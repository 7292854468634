import React from "react";
import Layout from "../components/Layout";
import SatisfactionContent from "../components/SatisfactionContent";

const Satisfaction = () => {
  return (
    <Layout>
      <SatisfactionContent />
    </Layout>
  );
};

export default Satisfaction;
