import React from "react";
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import Logo from "../../assets/images/logo.png";
import Face from "../../assets/images/facebook.png";
import Insta from "../../assets/images/instagram.png";
import Whatsapp from "../../assets/images/whatsapp.png";

const Footer = () => {
  return (
    <div className="footer py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-12 col-sm-12 ">
            <div className="d-flex align-items-center justify-content-center mb-4">
              <img src={Logo} width={100} />
              <h4 className="mt-1 text-white fs-2 fw-bold ps-3">
                Moonlight Fal Evi
              </h4>
            </div>
            <div className="d-flex mb-5 justify-content-center">
              <text className="text-white">
                © 2024 Moonlight. Her hakkı saklıdır.
              </text>
            </div>
          </div>

          <div className="col-lg-7 col-md-12 col-sm-12 offset-lg-1 py-4">
            <h4
              className="text-center text-white fs-4 mb-4"
              style={{ fontWeight: "600" }}
            >
              Bizimle İletişime Geçin
            </h4>
            <div className="row">
              <div className="d-flex justify-content-center">
                <div>
                  <a
                    href="https://www.facebook.com/MoonlightFalEvi"
                    target="_blank"
                  >
                    <img src={Face} width={50} className="m-3" />
                  </a>

                  <a
                    href="https://www.instagram.com/moonlight_fal_evi/?hl=tr"
                    target="_blank"
                  >
                    <img src={Insta} width={50} className="m-3" />
                  </a>
                  <WhatsAppWidget
                    phoneNumber="905455135654"
                    textReplyTime="Çevrimiçi"
                    message="Size nasıl yardımcı olabilirim?"
                    companyName="Moonlight Fal Evi"
                    sendButton="Gönder"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex justify-content-center">
                <text className="text-white fs-4 mt-3">
                  <i className="fa fa-phone me-3" />
                  +90 545 513 5654
                </text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

/* 
<text className="text-white fs-4">
                    <i className="fa fa-phone me-3"/>
                  +90 545 513 5654
                  </text>

                  <img src={Face} width={50} className="m-3" />
                <img src={Insta} width={50} className="m-3" />
                <img src={Whatsapp} width={50} className="m-3" />
*/
