import React from "react";
import Icon from "../assets/images/satisfaction.png";
import T1 from "../assets/images/t1.jpeg";

const SatisfactionContent = () => {
  return (
    <div>
      <div className="container ">
        <div className="d-flex justify-content-center mt-5 mb-4">
          <img src={Icon} width={150} />
        </div>
        <div className="justify-content-center align-items-center service-head">
          <h1 className="text-white text-center fs-1 fw-bold">
            〰︎ Sizden Gelenler 〰︎
          </h1>
        </div>
      </div>
      <div className="container py-5">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-3 col-md-6 mb-4 ">
            <div className="gradient-border d-flex">
              <img
                style={{ maxWidth: 305, minWidth: 300 }}
                className=""
                src={T1}
                width={"full"}
                height={350}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 ">
            <div className="gradient-border d-flex">
              <img className="" src={T1} width={300} height={350} />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 ">
            <div className="gradient-border d-flex">
              <img className="" src={T1} width={300} height={350} />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 ">
            <div className="gradient-border d-flex">
              <img className="" src={T1} width={300} height={350} />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4 ">
            <div className="gradient-border d-flex">
              <img className="" src={T1} width={300} height={350} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SatisfactionContent;
