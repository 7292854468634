import "./App.scss";
import { Button } from "react-bootstrap";
import React from "react";
import Router from "./Router";

function App() {
  return <Router />;
}

export default App;
