import React from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import HeaderLogo from "../../assets/images/header-logo.png";

const Header = () => {
  return (
    <Navbar className="header" expand="lg">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <div className="d-flex align-items-center">
            <img className="image-fluid" src={HeaderLogo} width={75} />
            <h3 className="d-flex ps-2 text-white fs-2">Moonlight Fal Evi</h3>
          </div>
        </Link>
        <Navbar.Toggle className="toggle" />
        <Navbar.Collapse>
          <Nav className="me-auto my-lg-0" style={{ maxHeight: "100px" }}></Nav>
          <div className="header-collapse">
            <Link className="header-link nav-link" to="/">
              Anasayfa
            </Link>
            <Link className="header-link nav-link" to="/services">
              Hizmetler
            </Link>
            <Link className="header-link nav-link" to="/satisfaction">
              Memnuniyet
            </Link>
          </div>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
