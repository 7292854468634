import React from "react";
import Logo from "../assets/images/logo.png";

const HomeLogo = () => {
  return (
    <div className="container ">
      <div className="d-flex justify-content-center mt-5 mb-5">
        <img src={Logo} width={200} />
      </div>
      <div className="justify-content-center align-items-center home-content">
        <h1 className="text-white text-center fs-1 fw-bold mb-5">〰︎ Hakkımızda 〰︎</h1>
        <div className="text-center home-content-text py-5">
          <text className="text-white">
            Moonlight Fal Evi'nin sahibi ve işletmecisiyim.Ledün İlmi , Gizli İlimler , Havas İlmi , Manevi Şifalandırma alanlarında
            9 yaşımdan itibaren 33 yıldır hizmet etmekteyim. Bu yeteneğimi kan soyumdan almaktayım. Uzun zamandır
            edinmiş olduğum tecrübelerim ve bilgi birikimlerim aracılığıyla sizlere en iyi hizmeti vermekteyim. Bugüne
            kadar hizmetimden memnun kalmayan olmamıştır. Hizmetimde gizlilik ve güven ilkeleri birinci prensibimizdir.
            Yaşadığınız sıkıntılar ne türde olursa olsun bana ulaşıp isteklerinize çözüm bulabilirsiniz.
          </text>
          <br />
          <br />
          <text className="text-white">
            "Talih bize bilgi öğretmez." Der Oscar Wilde. Ama bir deste tarot kartı, bize ihtiyacımız olan sonsuz
            rehberlik yapacaktır. Tarot, Antik Mısır Çağı'ndan bu yana birçok kültür ile değişime uğramış, harmanlanmış
            bir bilgi birikimini gösterir. Tarot, semboller ile bize rehberlik eden bir bilinçaltı okuma sanatıdır. Her
            kart bir sürpriz, her kart bir yol bizim için. Bir kahraman yolculuğa çıkar ve hikâye başlar. O uzun yolda
            başına gelecekler her bir kartta anlatılmıştır ve deneyimlemesi için onu beklemektedir. Siz de kendi
            hikâyenizin yolculuğuna çıkmak isterseniz size de en cömert şekli ile rehber olmaya hazır olacaktır.
          </text>
          <br />
          <br />
          <text className="text-white">
            Tarot danışmanlığımla birlikte hayatınıza istediğiniz şekilde yön verip daha huzurlu, mutlu, temkinli ve
            KENDİNİZİN yönettiği bir hayata sahip olabilirsiniz.
          </text>
        </div>
      </div>
    </div>
  );
};

export default HomeLogo;
