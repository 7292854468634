import React from "react";
import Icon from "../assets/images/tarot_logo.png";
import Buyu from "../assets/images/büyü.jpg";
import Kahve from "../assets/images/kahve.png";
import Tarot from "../assets/images/tarot.png";
import Kursun from "../assets/images/kursun.png";
import Koruma from "../assets/images/koruma.png";
import Spirituel from "../assets/images/spirituel.png";
import Sifalandirma from "../assets/images/sifalandirma.png";
import Psisik from "../assets/images/psisik-tedavi.png";

const ServicesContent = () => {
  return (
    <div>
      <div className="container ">
        <div className="d-flex justify-content-center mt-5 mb-4">
          <img src={Icon} width={150} />
        </div>
        <div className="justify-content-center align-items-center service-head">
          <h1 className="text-white text-center fs-1 fw-bold">
            〰︎ Hizmetlerimiz 〰︎
          </h1>
        </div>
      </div>
      <div className="service-content">
        <div className="container py-5 mb-5">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6 col-9 mb-4">
              <div className="card d-flex">
                <img src={Kahve} className="" />
                <div className="card-body border rounded-1">
                  <p className="card-text text-center text-white">Kahve Falı</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-9 mb-4">
              <div className="card d-flex">
                <img src={Tarot} />
                <div className="card-body border rounded-1">
                  <p className="card-text text-center text-white">
                    Tarot Danışmanlığı
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-9 mb-4">
              <div className="card d-flex">
                <img src={Kursun} />
                <div className="card-body border rounded-1">
                  <p className="card-text text-center text-white">
                    Kurşun Dökümü
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-9 mb-4">
              <div className="card d-flex">
                <img src={Spirituel} />
                <div className="card-body border rounded-1">
                  <p className="card-text text-center text-white">
                    Spiritüel Danışmanlık
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-9 mb-4">
              <div className="card d-flex">
                <img src={Buyu} />
                <div className="card-body border rounded-1">
                  <p className="card-text text-center text-white">Büyü Bozma</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-9 mb-4">
              <div className="card d-flex">
                <img src={Sifalandirma} />
                <div className="card-body border rounded-1">
                  <p className="card-text text-center text-white">
                    Şifalandırma
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-9 mb-4">
              <div className="card d-flex">
                <img src={Psisik} />
                <div className="card-body border rounded-1">
                  <p className="card-text text-center text-white">
                    Psişik Tedavi
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-9 mb-4">
              <div className="card d-flex">
                <img src={Koruma} />
                <div className="card-body border rounded-1">
                  <p className="card-text text-center text-white">Koruma</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesContent;
