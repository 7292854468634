import { Routes, Route } from "react-router-dom";
import App from "./App";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Satisfaction from "./pages/Satisfaction";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/services" element={<Services />} />
      <Route path="/satisfaction" element={<Satisfaction />} />
    </Routes>
  );
};

export default Router;
