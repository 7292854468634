import React from "react";
import Layout from "../components/Layout";
import ServicesContent from "../components/ServicesContent";

const Services = () => {
  return (
    <Layout>
      <ServicesContent />
    </Layout>
  );
};

export default Services;
