import React from "react";
import HomeContent from "../components/HomeContent";
import Layout from "../components/Layout";

const HomePage = () => {
  return (
    <Layout>
      <HomeContent />
    </Layout>
  );
};

export default HomePage;
